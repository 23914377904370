import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
        <Route
          exact
          path="/terms-of-service"
          component={TermsOfService}
        ></Route>
        <Home></Home>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
