import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  return (
    <footer id="footer" style={{ marginTop: "3rem" }}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 footer-contact">
              <h3>Stock Overflow </h3>
              <p>
                541, Varthur Main Rd,<br></br> Silver Springs Layout,
                Munnekollal,
                <br />
                Bengaluru, Karnataka 560066 <br />
                <br />
                <br />
                <strong>Email:</strong> contact@stockoverflow.co.in
                <br />
              </p>
            </div>

            <div className="col-lg-6 col-md-6 footer-links">
              <h4>Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/#about">About us</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/#services">Services</a>
                </li>

                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/terms-of-service">Terms of service</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright-wrap d-md-flex py-4">
          <div className="mr-md-auto text-center text-md-left">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Stock Overflow</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="www.x.com" className="twitter">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="www.instagram.com" className="instagram">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="www.linkedin.com" className="linkedin">
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
