import React from "react";

import { Link } from "react-router-dom";
import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/Navbar";
import "./info.css";

export default function Home(props: any) {
  return (
    <React.Fragment>
      <Navbar></Navbar>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div
            className="row justify-content-center"
            style={{ marginTop: "6rem" }}
          >
            <div className="col-xl-6 col-lg-6 col-md-12 justify-content-center">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
                style={{ background: "#5846f9", color: "white" }}
              >
                <strong>Disclaimer!</strong> We are not SEBI Registered
                Advisors. This website and app are tools to help you find
                bulk/block deals and shall not be responsible for your profit or
                loss. Whatever you invest is completely your decision.
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Stock Overflow</h1>

              <h2>
                Your gateway to smart investing through bulk and block deal
                notifications on the Indian stock exchange.
              </h2>

              <div>
                <Link to="/signup" className="btn-get-started scrollto">
                  Get Started
                </Link>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <img
                src="https://ik.imagekit.io/idsecure/d60533f9-83d2-4325-b4a7-5b9b807c5efb.png?updatedAt=1700291931349"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" className="about how-it-works">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="https://ik.imagekit.io/idsecure/26ab8a85-cb81-4ca5-bb20-537f97f703ea.svg?updatedAt=1700291961770"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-9 pt-4 pt-lg-0 order-2 order-lg-1 content"
                data-aos="fade-right"
              >
                <h3>About </h3>
                <br></br>
                <p>
                  At StockOverflow, we empower retail investors with timely
                  insights into the bulk deals and block deals happening in the
                  Indian stock market. By tracking the moves of institutional
                  and big-money investors, we enable you to replicate their
                  strategies with our notifications — perfect for those
                  interested in copycat investing.
                </p>
                <ul>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    {"  "} Quick Notifications: Get alerts as soon as bulk and
                    block deals are reported on the stock exchange.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"> </i>
                    {"  "} Comprehensive Data: We aggregate and display detailed
                    information on deals, including share quantities, prices,
                    and the investors involved.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    {"  "} Custom Alerts: Tailor your notifications to focus on
                    specific stocks or sectors of interest.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    {"  "} Copycat Investing Insights: We provide strategic
                    insights to help you mimic successful trades from industry
                    giants.
                  </li>
                </ul>
                {/* <a href="#" className="read-more">
                  Read More <i className=""></i>
                </a> */}
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about how-it-works">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="https://ik.imagekit.io/idsecure/26ab8a85-cb81-4ca5-bb20-537f97f703ea.svg?updatedAt=1700291961770"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-9 pt-4 pt-lg-0 order-2 order-lg-1 content"
                data-aos="fade-right"
              >
                <h3>How it works </h3>
                <br></br>

                <ul>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    {"  "} Sign Up: Create your account to get started with
                    StockOverflow.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"> </i>
                    {"  "} Set Preferences: Choose your favorite stocks or
                    sectors.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    {"  "} Get Notifications: Receive updates on bulk and block
                    deals directly on your device.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    {"  "} Act: Follow the big players and take your investment
                    decisions to the next level.
                  </li>
                </ul>
                {/* <a href="#" className="read-more">
                  Read More <i className=""></i>
                </a> */}
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </React.Fragment>
  );
}
